<template>
  <div
    class="form-field auth-inner py-2"
    style="line-height: 1.6; width: 100%;"
  >

    <!-- Forgot Password v1 -->
    <b-card class="mb-0 px-1">
      <!--        <b-card-text class="mb-2">-->
      <!--          Enter your form-->
      <!--        </b-card-text>-->

      <!-- form -->
      <validation-observer
        v-if="formSucceded === false"
        ref="simpleRules"
        #default="{invalid}"
      >
        <b-form
          class="auth-forgot-password-form mt-2"
          @submit.prevent="validationForm"
        >
          <!-- Row Loop -->
          <div
            v-for="(item, index) in formData.labels"
            :key="index"
            ref="row"
          >
            <validation-provider
              #default="{ errors }"
              :name="item"
              :rules="formData.required[index] !== undefined && formData.required[index] == true? 'required' : ''"
            >
              <b-form-group
                v-if="formData.types[index] === 'select'"
                :label="item"
                :label-for="formData.names[index]"
              >
                <b-form-select
                  v-model="formStore[formData.names[index]]"
                  :options="formData.options[index]"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>

              <b-form-group
                v-else-if="formData.types[index] === 'textarea'"
                :label="item"
                :label-for="formData.names[index]"
              >
                <b-form-textarea
                  :id="formData.names[index]"
                  v-model="formStore[formData.names[index]]"
                  :state="errors.length > 0 ? false:null"
                  trim
                  rows="2"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>

              <b-form-group
                v-else-if="formData.types[index] === 'checkbox'"
                :label="item"
                :label-for="formData.names[index]"
              >
                <b-form-checkbox-group
                  :id="formData.names[index]"
                  v-model="formStore[formData.names[index]]"
                  :state="errors.length > 0 ? false:null"
                  :name="formData.names[index]"
                  :options="formData.options[index]"
                  stacked
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>

              <b-form-group
                v-else-if="formData.types[index] === 'radio'"
                :label="item"
                :label-for="formData.names[index]"
              >
                <b-form-radio-group
                  :id="formData.names[index]"
                  v-model="formStore[formData.names[index]]"
                  :options="formData.options[index]"
                  :state="errors.length > 0 ? false:null"
                  stacked
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>

              <b-form-group
                v-else-if="formData.types[index] === 'date'"
                :label="item"
                :label-for="formData.names[index]"
              >
                <flat-pickr
                  :id="formData.names[index]"
                  v-model="formStore[formData.names[index]]"
                  class="form-control"
                  :options="formData.options[index]"
                  :config="{ dateFormat: 'd-m-Y'}"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>

              <b-form-group
                v-else
                :label="item"
                :label-for="formData.names[index]"
              >
                <b-form-input
                  :id="formData.names[index]"
                  v-model="formStore[formData.names[index]]"
                  :type="formData.types[index]"
                  :state="errors.length > 0 ? false:null"
                  :name="formData.names[index]"
                  placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

          </div>

          <div v-if="inputManually">
            <b-form-group>
              <label for="selected_salesperson">Assign Salesperson</label>
              <v-select
                id="selected_salesperson"
                ref="selected_salesperson"
                v-model="formData.selectedSalesperson"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                :reduce="label => label.value"
                :options="assignedSalespersons"
              />
              <b-form-invalid-feedback :state="assignedSalespersonState">
                <span class="text-danger font-small-3">{{ assignedSalespersonError }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
          </div>

          <!-- aggreement -->
          <div v-if="aggreement !== ''">
            <div class="demo-inline-spacing">
              <b-form-checkbox
                v-model="formData.aggreement"
                :state="aggreementState"
                :value="false"
              >
                <span
                  id="aggreement"
                  v-html="aggreement"
                />
              </b-form-checkbox>
              <b-form-invalid-feedback :state="aggreementState">
                <span class="text-danger font-small-3">{{ aggreementError }}</span>
              </b-form-invalid-feedback>
            </div>
          </div>
          <!-- submit button -->
          <div class="d-flex justify-content-center">
            <span
              v-if="!userSelfData || ($router.currentRoute.params.slug !== undefined && $router.currentRoute.params.slug.slice(0, 2) === '$2')"
            >
              <b-button
                class="mt-2 px-md-3"
                block
                type="submit"
                :hidden="hiddenSubmitButton"
                :disabled="invalid || disableBtn"
                :style="{background: submitButton.color + ' !important', border: 0, width:'fit-content'}"
                @click="recaptcha"
              >
                <span class="align-middle">{{ submitButton.label }}</span>
              </b-button>
            </span>

            <span
              v-else
              id="disabled-form-field"
            >
              <b-button
                class="mt-2 px-md-3"
                block
                type="submit"
                disabled
                :hidden="hiddenSubmitButton"
                :style="{background: submitButton.color + ' !important', border: 0, width:'fit-content'}"
              >
                <span class="align-middle">{{ submitButton.label }}</span>
              </b-button>
              <b-tooltip
                target="disabled-form-field"
                triggers="hover"
              >
                During landing page preview, all form submissions will not be recorded. Try access this Landing Page with logged out browser.
              </b-tooltip>
            </span>
          </div>
        </b-form>
      </validation-observer>

      <b-card-text
        v-if="formSucceded === true"
        class="text-center mt-2"
      >
        <h4 class="text-success mb-1">
          Thanks for filling this form
        </h4>
        <b-link :to="{name:'auth-login'}">
          <feather-icon icon="ChevronLeftIcon" /> Back to Home Apps
        </b-link>
      </b-card-text>

    </b-card>
    <!-- /Forgot Password v1 -->
  </div>
</template>

<script>
import Vue from 'vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BTooltip, BCard, BLink, BCardText, BFormGroup, BFormInput, BForm, BButton, BFormTextarea, BFormSelect, BFormRadioGroup, BFormCheckboxGroup, BFormCheckbox, BFormInvalidFeedback,
} from 'bootstrap-vue'
import store from '@/store'
import vSelect from 'vue-select'
import { required, email } from '@validations'
import { useToast } from 'vue-toastification/composition'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    // VuexyLogo,
    BTooltip,
    BCardText,
    BCard,
    BLink,
    BFormCheckbox,
    BFormGroup,
    BFormTextarea,
    BFormSelect,
    BFormInput,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BFormInvalidFeedback,
    BButton,
    BForm,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    flatPickr,
  },
  props: {
    isLandingPageShow: {
      type: Boolean,
      default: false,
    },
    hiddenSubmitButton: {
      type: Boolean,
      required: true,
    },
    inputManually: {
      type: Boolean,
      required: false,
    },
    assignedSalespersons: {
      type: Array,
      default: () => {
        return []
      },
    },
    formId: {
      type: Number,
      required: false,
      default: 0,
    },
    landingPageUuid: {
      type: String,
      required: false,
      default: '',
    },
    aggreement: {
      type: String,
      required: false,
      default: '',
    },
    submitButton: {
      type: Object,
      required: false,
      default: () => {
        return {}
      },
    },
    userSelfData: {
      type: Object,
      required: false,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      disableBtn: false,
      formSucceded: false,
      formStore: {},
      // validation
      required,
      email,
      formData: {
        slug: null,
        title: null,
        labels: [],
        types: [],
        required: [],
        options: [],
        aggreement: '',
        selectedSalesperson: null,
      },
      richTextArea: '',
      aggreementError: '',
      assignedSalespersonError: '',
      aggreementState: null,
      assignedSalespersonState: null,
      recaptchaToken: null,
    }
  },
  watch: {
    formId(){
      this.fetchData()
    },
    // formId: {
    //   immediate: true,
    //   handler() {
    //     this.fetchData()
    //   },
    // },
  },
  created() {
    if (this.formId != null){
      this.fetchData()
    }
    if (this.isLandingPageShow === true){
      Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY })
    }
  },
  methods: {
    changeRichText() {
      const temp = {}
      this.formData.types.forEach((type, index) => {
        if (type === 'textarea' && this.formData.required[index] !== false) {
          const value = this.formStore[this.formData.names[index]]
          const formBreak = value.replace(/\r\n|\r|\n/g, '<br>')
          const formSpace = formBreak.replace(/ /g, '&nbsp;')
          temp[this.formData.names[index]] = formSpace
        } else {
          temp[this.formData.names[index]] = this.formStore[this.formData.names[index]]
        }
      })
      return temp
    },
    fetchData() {
      store.dispatch('menu-form/getFormById', { id: this.formId })
        .then(response => {
          this.formData = response.data.data
          this.formData.labels = JSON.parse(this.formData.labels)
          this.formData.names = JSON.parse(this.formData.names)
          this.formData.options = JSON.parse(this.formData.options)
          this.formData.required = JSON.parse(this.formData.required)
          this.formData.types = JSON.parse(this.formData.types)
          this.formData.length = this.formData.labels.length
        })
    },
    async recaptcha() {
      await this.$recaptchaLoaded().then(async () => {
        this.$recaptchaLoaded()

        // Execute reCAPTCHA with action "login".
        const token = await this.$recaptcha('login')
        this.recaptchaToken = token
      })
    },
    validationForm() {
      this.disableBtn = true
      this.aggreementState = null
      this.aggreementError = ''
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const tempFormStore = this.changeRichText()
          // get URL
          const uri = window.location.search.substring(1)
          const params = new URLSearchParams(uri)

          store.dispatch('menu-form/inputForm', {
            form_data: tempFormStore,
            aggreement: this.formData.aggreement,
            input_manually: router.currentRoute.params.slug.slice(0, 2) === '$2',
            selected_salesperson: this.formData.selectedSalesperson,
            form_id: this.formData.id,
            user_id: this.userSelfData == null ? null : this.userSelfData.id,
            landing_page_uuid: this.landingPageUuid,
            query_channel: params.get('channel'),
          })
            .then(response => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: 'Success',
                  icon: 'CheckIcon',
                  variant: 'success',
                  text: response.data.message,
                },
              })
              this.disableBtn = false
              this.formSucceded = true

              if (this.isLandingPageShow){
                this.$router.push({ name: 'landing-pages-thank-you', params: { uuid: this.landingPageUuid } })
              }
            })
            .catch(error => {
              if (error.response !== undefined){
                if (error.response.data.message.includes('agree') || error.response.data.message.includes('aggree')){
                  this.aggreementState = false
                  this.aggreementError = error.response.data.message
                } else if (error.response.data.message.includes('selected salesperson')) {
                  this.assignedSalespersonState = false
                  this.assignedSalespersonError = error.response.data.message
                } else {
                  this.toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Error has occured',
                      icon: 'XIcon',
                      variant: 'danger',
                      text: error.response.data.message,
                    },
                  })
                }
                this.disableBtn = false
              }
            })
        }
      })
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
    }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  @media (min-width: 1440px) {
    .form-field {
      width: 600px;
    }
  }
  #aggreement p {
    margin-bottom: 0;
  }
  #aggreement a {
    color: #06c !important;
  }
  .demo-inline-spacing > * {
    margin-right: 0;
  }
  .v-select > .vs__dropdown-toggle {
    height: 37.79px;
  }
  .vs__selected-options {
    flex-wrap: nowrap;
    max-width: calc(100% - 41px);
  }
  .vs__selected {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
  }
  .vs__search {
    position: absolute;
  }
  .vs--open .vs__search {
    position: static;
  }
  .vs__dropdown-option {
    white-space: normal;
  }
</style>
