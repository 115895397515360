<template>
  <div class="auth-wrapper auth-v1 px-2">
    <form-field
      :hidden-submit-button="true"
      :form-id="parseInt($route.params.id)"
    />
  </div>
</template>

<script>
import FormField from './FormField.vue'

export default {
  components: {
    FormField,
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';
</style>
